<template>
  <b-modal ref="loanModal" id="loan_modal" hide-footer hide-header scrollable>
    <div>
      <div class="d-flex justify-content-between align-items-center my-2">
        <h3 class="w-700">Create/Edit Loan</h3>
      </div>
      <hr />
      <b-form-group id="lblCustomer" label="" label-for="txtCustomer">
        <b-input-group>
          <b-form-input
            placeholder="Enter Client's Account No Or NIC No"
            size="sm"
            v-model="searchParams"
            :disabled="!isCreated"
          ></b-form-input>
          <b-input-group-append>
            <b-button
              size="sm"
              variant="info"
              @click="searchCustomer"
              :disabled="!isCreated"
              >Search</b-button
            >
          </b-input-group-append> </b-input-group
        ><span class="p-font">{{ form.lend_by.full_name }}</span>
      </b-form-group>
      <b-form-group id="lblLoanAmount" label="" label-for="txtLoanAmount">
        <b-form-input
          class="form-control col-12"
          id="txtLoanAmount"
          size="sm"
          name="txtLoanAmount"
          :disabled="!isCreated"
          placeholder="Amount"
          type="number"
          v-model="$v.form.amount.$model"
          :state="validateState('amount')"
          aria-describedby="amount-live-feedback"
        ></b-form-input>
        <b-form-invalid-feedback id="amount-live-feedback">
          Amount is required.
        </b-form-invalid-feedback>
      </b-form-group>
      <div class="d-flex">
        <b-form-group
          id="lblTenorType"
          label="Tenor Type"
          label-for="drpTenorType"
          class="mr-2"
        >
          <b-form-select
            v-model="$v.form.tenor_type.$model"
            :options="tenorTypes"
            id="drpTenorType"
            name="drpTenorType"
            @change="tenorTypeChange"
            :disabled="!isCreated"
            size="sm"
          ></b-form-select>
          <b-form-invalid-feedback id="tenor_type-live-feedback">
            Tenor Type is required.
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          id="lblTenor"
          label="Tenor(Monthly)"
          label-for="txtTenor"
          class="mr-2"
        >
          <b-form-input
            class="form-control col-12"
            id="txtTenor"
            size="sm"
            name="txtTenor"
            type="number"
            v-model="$v.form.tenor.$model"
            :state="validateState('tenor')"
            :disabled="!isCreated"
            aria-describedby="tenor-live-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="tenor-live-feedback">
            Tenor is required & can not be a negetive.
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group id="lblRate" label="Rate" label-for="txtRate">
          <b-form-input
            class="form-control col-12"
            id="txtRate"
            size="sm"
            name="txtRate"
            type="number"
            v-model="$v.form.rate.$model"
            :state="validateState('rate')"
            :disabled="!isCreated"
            aria-describedby="rate-live-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="rate-live-feedback">
            Rate is required & can not be a negetive.
          </b-form-invalid-feedback>
        </b-form-group>
      </div>
      <b-form-group id="lblEMI" label="EMI" label-for="txtEMI">
        <b-form-input
          class="form-control col-12"
          id="txtEMI"
          size="sm"
          name="txtEMI"
          type="text"
          disabled
          v-model="form.emi"
        ></b-form-input>
      </b-form-group>
      <b-form-group
        id="lblStatus"
        label="Status"
        label-for="drpStatus"
        class="mr-2"
      >
        <b-form-select
          v-model="form.status"
          :options="statusTypes"
          id="drpStatus"
          name="drpStatus"
          size="sm"
        ></b-form-select>
      </b-form-group>
    </div>
    <b-button
      size="sm"
      variant="outline-danger"
      class="mr-1"
      @click="resetForm"
      :disabled="!isCreated"
      >RESET</b-button
    >
    <b-button
      type="button"
      size="sm"
      variant="outline-primary"
      class="mr-1"
      @click="calculate"
      :disabled="!isCreated"
      >CALCULATE</b-button
    >
    <b-button
      type="submit"
      size="sm"
      variant="outline-primary"
      @click="onSubmit"
      >SUBMIT</b-button
    >
  </b-modal>
</template>
<script>
/* eslint-disable @typescript-eslint/camelcase */
import { validationMixin } from "vuelidate";
import { required, maxValue, minValue } from "vuelidate/lib/validators";
import ApiService from "@/services/api.service";
import Swal from "sweetalert2";
export default {
  mixins: [validationMixin],
  name: "loan-form",
  data() {
    return {
      isCreated: true,
      searchParams: null,
      tenorTypes: [
        { value: "monthly", text: "Monthly" },
        { value: "weekly", text: "Weekly" },
        { value: "daily", text: "Daily" }
      ],
      statusTypes: [
        { value: "-1", text: "Rejected" },
        { value: "0", text: "Pending" },
        { value: "1", text: "Processing" },
        { value: "3", text: "On Going" },
        { value: "4", text: "Completed" }
      ],
      form: {
        id: 0,
        reference_code: null,
        amount: null,
        total_payment: null,
        tenor: 0,
        rate: 0,
        emi: 0,
        tenor_type: "monthly",
        lend_by: {},
        handled_by: {},
        status: 0,
        remaining_installments: 0
      }
    };
  },
  validations: {
    form: {
      amount: {
        required
      },
      lend_by: {
        id: {
          required
        }
      },
      rate: {
        required,
        maxValue: maxValue(300),
        minValue: minValue(0)
      },
      tenor: {
        required,
        minValue: minValue(0)
      },
      tenor_type: {
        required
      },
      handled_by: {
        id: {
          required
        }
      }
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    tenorTypeChange() {
      const type = this.form.tenor_type;
      let emi = this.form.emi;
      switch (type) {
        case "weekly":
          emi = this.form.emi / 4;
          this.form.remaining_installments = this.form.tenor * 4;
          break;
        case "daily":
          emi = this.form.emi / 30;
          this.form.remaining_installments = this.form.tenor * 30;
          break;
        case "monthly":
          emi = this.form.emi / 1;
          this.form.remaining_installments = this.form.tenor * 1;
          break;
        default:
          emi = this.form.emi / 1;
          this.form.remaining_installments = this.form.tenor * 1;
          break;
      }
      this.form.emi = emi.toFixed(2);
    },
    searchCustomer() {
      const params = this.searchParams;
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const _self = this;
      ApiService.post("users", { searchParams: params }).then(({ data }) => {
        if (data.isSuccess && data.user != null) {
          _self.form.lend_by = data.user;
          _self.form.handled_by = _self.$store.getters.currentUser;
        } else {
          Swal.fire({
            icon: "error",
            text:
              "Request submittion failed, Try again or contact system admin",
            focusConfirm: true
          });
        }
      });
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      if (this.isCreated) {
        this.tenorTypeChange();
        ApiService.post("loans", this.form).then(({ data }) => {
          if (data.isSuccess) {
            Swal.fire({
              icon: "success",
              text: "Request is submitted successfully",
              focusConfirm: true
            });
          } else {
            Swal.fire({
              icon: "error",
              text:
                "Request submittion failed, Try again or contact system admin",
              focusConfirm: true
            });
          }
        });
      } else {
        ApiService.post("loans/" + this.form.id, this.form).then(({ data }) => {
          if (data.isSuccess) {
            Swal.fire({
              icon: "success",
              text: "Request is submitted successfully",
              focusConfirm: true
            });
          } else {
            Swal.fire({
              icon: "error",
              text:
                "Request submittion failed, Try again or contact system admin",
              focusConfirm: true
            });
          }
        });
      }
      this.$refs["loanModal"].hide();
      this.$parent.callByComponent();
    },
    calculate() {
      const n = this.form.tenor;
      const a = this.form.amount;
      const r = this.form.rate / (100 * 12);
      const x = Math.pow(1 + r, n);
      const y = x - 1;
      const ratio = x / y;
      const emi = a * r * ratio;
      this.form.emi = emi.toFixed(2);
      this.form.total_payment = (emi * n).toFixed(2);
      //this.form.totalInterest = (emi * n - a).toFixed(2);
    },
    resetForm() {
      this.form = {
        id: 0,
        reference_code: null,
        total_payment: null,
        amount: null,
        tenor: 0,
        rate: 0,
        emi: 0,
        tenor_type: "monthly",
        lend_by: {},
        handled_by: {},
        status: 0,
        remaining_installments: 0
      };
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    getIdFromChild(id) {
      this.$refs.loanModal.show();
      if (id != 0) {
        ApiService.get("loans/" + id).then(({ data }) => {
          this.isCreated = false;
          this.form = data.loan;
        });
      } else {
        this.isCreated = true;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.p-font,
.form-group {
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
}
.row {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
</style>
